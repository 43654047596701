/**
 * Skipped minification because the original files appears to be already minified.
 * Original file: /npm/videojs-http-source-selector@1.1.6/dist/videojs-http-source-selector.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
/**
 * videojs-http-source-selector
 * @version 1.1.6
 * @copyright 2019 Justin Fujita <Justin@pivotshare.com>
 * @license MIT
 */
.video-js.vjs-http-source-selector{display:block}
